import { graphql } from 'gatsby';
import React from 'react';
import { Banner } from '../../components/Banner';
import { ContactSection } from '../../components/ContactSection';
import { Hero, HeroImageFragment, SrcMap } from '../../components/Hero';
import { Layout } from '../../components/Layout';
import { ContentfulRichText } from '../../components/RichText';
import { Section } from '../../components/Section';
import { ServiceCard } from '../../components/ServiceCard';
import { urls } from '../../constants';
import { styled } from '../../styles/styled';

const Cards = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  grid-gap: ${(props) => props.theme.spacing(2)};
  @media (min-width: ${(props) => props.theme.bp.lg}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

interface Props {
  data: {
    service: {
      title: string;
      includeContactForm?: boolean;
      image: HeroImageFragment;
      content: {
        content: string;
      };
      contentTitle: {
        contentTitle: string;
      };
      contentSubtitle: {
        contentSubtitle: string;
      };
      services: Array<{
        id: string;
        slug: string;
        shortTitle: string;
        shortDescription: {
          shortDescription: string;
        };
      }>;
    };
  };
}

export default (props: Props) => {
  const { title, image, includeContactForm } = props.data.service;
  const imageMap: SrcMap = {
    sm: image.sm.src,
    md: image.md.src,
    lg: image.lg.src,
    xl: image.xl.src,
  };
  const parsedContent = props.data.service.content
    ? JSON.parse(props.data.service.content.content)
    : {};
  const parsedContentTitle = props.data.service.contentTitle
    ? JSON.parse(props.data.service.contentTitle.contentTitle)
    : {};
  const parsedContentSubtitle = props.data.service.contentSubtitle
    ? JSON.parse(props.data.service.contentSubtitle.contentSubtitle)
    : {};

  return (
    <Layout title={title}>
      <Hero imageSrc={imageMap} title={title} />
      <Section
        alt
        title={<ContentfulRichText node={parsedContentTitle} />}
        subTitle={<ContentfulRichText node={parsedContentSubtitle} />}
      >
        <ContentfulRichText node={parsedContent} />
        <Cards>
          {props.data.service.services.map((subService) => (
            <ServiceCard
              key={subService.id}
              to={subService.slug}
              title={subService.shortTitle}
            >
              <ContentfulRichText
                node={
                  subService.shortDescription
                    ? JSON.parse(subService.shortDescription.shortDescription)
                    : {}
                }
              />
            </ServiceCard>
          ))}
        </Cards>
      </Section>
      {includeContactForm && (
        <Section
          title="Want to talk?"
          subTitle="Contact us today to discuss your specific needs and objectives"
        >
          <ContactSection />
        </Section>
      )}
    </Layout>
  );
};

export const query = graphql`
  query ClinicalRiskAssessmentsService {
    service: contentfulService(slug: { eq: "/clinical-risk-assessments" }) {
      title
      includeContactForm
      image {
        ...HeroImage
      }
      content {
        content
      }
      contentTitle {
        contentTitle
      }
      contentSubtitle {
        contentSubtitle
      }
      services {
        id
        shortTitle
        slug
        shortDescription {
          shortDescription
        }
      }
    }
  }
`;
